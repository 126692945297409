/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Global CSS
import './src/css/global.css';

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
