// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-template-js": () => import("../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-blog-template-js": () => import("../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-over-js": () => import("../src/pages/over.js" /* webpackChunkName: "component---src-pages-over-js" */),
  "component---src-pages-producten-js": () => import("../src/pages/producten.js" /* webpackChunkName: "component---src-pages-producten-js" */),
  "component---src-pages-zoeken-js": () => import("../src/pages/zoeken.js" /* webpackChunkName: "component---src-pages-zoeken-js" */)
}

